<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <router-link class="btn-green" to="/user/new">
              {{ $t("create_user") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="tableColumn"
          :rows="users"
          :search-options="{
            enabled: true,
            placeholder: 'Sök',
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'"> {{ props.row.firstname }} {{ props.row.lastname }} </span>
            <span v-else-if="props.column.field == 'role'">
              <span v-if="props.row.role == 'user'">{{ $t("user_role.department") }}</span>
              <span v-if="props.row.role == 'superuser'">{{ $t("user_role.customer") }}</span>
              <span v-if="props.row.role == 'admin'">{{ $t("user_role.admin") }}</span>
              <span v-if="props.row.role == 'alarm_center'">{{ $t("user_role.alarm_center") }}</span>
              <span v-if="props.row.role == 'tech'">{{ $t("user_role.tech") }}</span>
              <span v-if="props.row.role == 'reseller'">{{ $t("user_role.reseller") }}</span>
              <span v-if="props.row.role == 'seller'">{{ $t("user_role.seller") }}</span>
              <span v-if="props.row.role == 'securdesktop'">SecurDesktop</span>
            </span>
            <span v-else-if="props.column.field == 'edit'">
              <button class="btn-secondary-outline" @click.prevent="showUpdate(props.row)">
                {{ $t("edit") }}
                <BaseIcon icon="pencil-alt" class="ml-1" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'delete'">
              <button class="btn-red-outline" @click.prevent="deleteUserPrompt(props.row)">
                {{ $t("delete") }}
                <BaseIcon icon="trash" class="ml-1" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <UserUpdate :userInfo="updateInfo" :roles="roles" :allCustomers="customers" :allUnits="units" :alarm_centers="alarm_centers" :resellers="resellers" :sellers="sellers" :technicians="technicians" @updateUser="getUsers()" />
  </div>
</template>

<script>
import UserUpdate from "@/components/modal/user_update";

export default {
  name: "Users",
  title() {
    return this.$t("page_titles.user");
  },
  components: {
    UserUpdate,
  },
  data() {
    return {
      users: [],
      customers: [],
      units: [],
      alarm_centers: [],
      resellers: [],
      sellers: [],
      technicians: [],
      updateInfo: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    tableColumn() {
      return [
        { label: this.$t("user_table.name"), field: "name", sortable: false },
        { label: this.$t("user_table.user_name"), field: "username", sortable: false },
        { label: this.$t("user_table.email"), field: "email", sortable: false },
        { label: this.$t("user_table.telephone"), field: "phone", sortable: false },
        { label: this.$t("user_table.role"), field: "role", sortable: true },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ];
    },
    roles() {
      return [
        { id: "admin", name: this.$t("user_role.admin") },
        { id: "superuser", name: this.$t("user_role.customer") },
        { id: "user", name: this.$t("user_role.department") },
        { id: "alarm_center", name: this.$t("user_role.alarm_center") },
        { id: "tech", name: this.$t("user_role.tech") },
        { id: "reseller", name: this.$t("user_role.reseller") },
        { id: "seller", name: this.$t("user_role.seller") },
        { id: "securdesktop", name: "SecurDesktop"},
      ];
    },
  },
  methods: {
    getUsers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/users`)
        .then((response) => {
          this.users = response.data.users;
        })
        .catch((errors) => {
          this.handleError(errors);
        });
    },

    async getCustomers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/customer/`);
        this.customers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getUnits() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/units/`);
        this.units = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getAlarmCenters() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`);
        this.alarm_centers = response.data.data;

        if (this.user.role == "reseller") {
          let resellerAlarmCenterId = this.$store.state.user.reseller_alarm_center;
          let resellerAlarmCenter = response.data.data.find(({_id}) => _id === resellerAlarmCenterId);
          this.alarm_centers = resellerAlarmCenter ? [resellerAlarmCenter] : [];
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    async getResellers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/reseller`);
        this.resellers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getSellers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/sellers`);
        this.sellers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getTechnicians() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/technicians`);
        this.technicians = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    deleteUserPrompt(user) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">${this.$t("delete_user")} ${user.username}?</span>`,
        buttons: [
          {
            title: `<div class="bg-accent-500 text-white text-sm font-sans py-2">${this.$t("user_actions.cancel")}</div>`,
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: `<div class="bg-red-500 text-white text-sm font-sans py-2">${this.$t("delete")}</div>`,
            handler: () => {
              this.deleteUser(user._id);
            },
          },
        ],
      });
    },

    async deleteUser(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/user/${_id}`);

        this.$modal.hide("dialog");
        this.handleSuccess(this.$t("user_removed_message"));

        this.getUsers();
      } catch (error) {
        this.$modal.hide("dialog");

        let error_message = this.$t("problem_occured");
        if (error.response && error.response.status == 400) error_message = this.$t("insufficient_permissions");
        this.handleError(error, error_message);
      }
    },

    showUpdate(user) {
      this.updateInfo = _.cloneDeep(user);
      this.$modal.show("update-user");
    },

    hide(id) {
      this.$modal.hide(id);
      this.edit_user = null;
    },
  },

  created() {
    this.getUsers();
    this.getCustomers();
    this.getUnits();
    if (this.user.role == "admin") {
      this.getAlarmCenters();
      this.getResellers();
      this.getSellers();
      this.getTechnicians();
    }
    if (this.user.role == "reseller") {
      this.getAlarmCenters();
    }

    this.setPageTitle(this.$t("top_titles.user"), "users");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.user");
      this.setPageTitle(this.$t("top_titles.user"), "users");
    },
  },
};
</script>
